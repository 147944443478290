.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1281px) {
  .desktop-hidden {
    display: none;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .mobile-hidden {
    display: none;
  }
}

// .intro {
//   color: #F5AFBB;
//   margin-top: 10%;
//   margin-left: 25%;
//   margin-bottom: 0;
//   font-size: 3em;
// }

// .name {
//   color: #FAF9F6;
//   font-size: 5em;
//   margin-top: 0;
//   margin-left: 30%;
//   margin-bottom: 0;
// }

// .info {
//   color: #D3D3D3;
//   margin-top: 0;
//   margin-left: 30%;
//   width: 50%;
//   font-weight: bolder;
//   font-size:larger;
// }

// .hyperlink {
//   text-decoration: none;
//   color: #D3D3D3;
// }

// button {
//   border: 2px solid #F5AFBB;
//   cursor: pointer;
//   background-color: transparent;
//   color: #F5AFBB;
//   border-radius: 0.25em;
//   margin-left: 30%;
//   margin-top: 1.5%;
//   height: 60%;
//   width: 20%;
//   padding: 1.25rem 1.75rem;
//   font-size: 18px;
//   font-family: 'Thasadith', sans-serif;
//   font-weight: bolder;
//   line-height: 1;

// }