.intro {
    color: #F5AFBB;
    margin-top: 5%;
    margin-left: 25%;
    margin-bottom: 0;
    font-size: 3em;
  }
  
  .name {
    color: #FAF9F6;
    font-size: 5em;
    margin-top: 0;
    margin-left: 30%;
    margin-bottom: 0;
  }
  
  .info {
    color: #D3D3D3;
    margin-top: 0;
    margin-left: 30%;
    width: 50%;
    font-weight: bolder;
    font-size:x-large;
  }
  
  .hyperlink {
    text-decoration: none;
    color: #D3D3D3;
  }
  
  button {
    border: 2px solid #F5AFBB;
    cursor: pointer;
    background-color: transparent;
    color: #F5AFBB;
    border-radius: 0.25em;
    margin-left: 30%;
    margin-top: 1.5%;
    height: 90%;
    width: 100%;
    padding: 1.25rem 1.75rem;
    font-size: 1.6em;
    font-family: 'Thasadith', sans-serif;
    font-weight: bolder;
    line-height: 1;
  }

  .link-container {
    display: flex;
    justify-content: center ;
  }

  @media screen and (max-width: 600px) {
    .info {
      width: 85%;
      margin-left: 10%;
      margin-top: 5%;
    }

    .name {
      font-size: 3em;
      margin-left: 40%;
    }

    .intro {
      margin-left: 10%;
    }

    .link-container {
      display: flex;
      flex-direction: column;
      margin-right: 40%;
    }

    button {
      margin-top: 10%;
    }
  }