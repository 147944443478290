.about {
    color: #FAF9F6;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #FAF9F6;
    font-weight: bolder;
    font-size: 1.2rem;
    // display: flex;
    // justify-content: center;

}

.about-header {
    color: #F5AFBB;
    font-size: 3em;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 0;
    // display: flex;
    // justify-content: center;
}

.hello {
  margin-top: 0;
  font-size: 2em;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 60%;
  margin-bottom: 0;
  margin-right: 10%;
  padding-left: 20%;
}

.columns {
  margin: 3%;
  margin-top: 0;
}

.tech-header {
  text-align: center;
  margin-bottom: 0;
}

.about-paragraph {
  text-align: left;
  width: 100%;
  font-size: large;
}

.about-container {
  display: flex;
  justify-content: space-around;
  border: 10px solid #F5AFBB;
  height: fit-content;
  border-radius: 4%;
  width: 80%;
  padding: 3%;
}

.about-container div {
  flex-basis: 100%;
}

.chez-img {
  border-radius: 10%;
  margin-top: 6%;
  margin-right: 7%;
  height: 90%;
  width: 100%;
}

.about-text {
  margin: 3%;
  margin-top: 2%;
}

.technology-list {
  border: 2px solid #F5AFBB;
  border-radius: 4%;
}


@media screen and (max-width: 600px) {
  .hello {
    width: 83%;
  }

  .about-container {
    flex-direction: column-reverse;
  }
  }
