.project-card {
  margin-bottom: 5%;
  display: grid;
  grid-template-columns: 50% 50%;
  border: 10px solid #F5AFBB;
  width: 80%;
  height: 45em;
  border-radius: 4%;
  align-self: center;
}

.project-imgs {
  margin-top: 10%;
  height: 50%;
  width: 90%;
  display: flex;
  margin-left: 4%;
  border: 5px solid #F5AFBB;
  border-radius: 4%;
}


.project-title {
  font-size: 2.2em;
  color: #FAF9F6;
  font-weight: bolder;
  text-align: center;
}

.project-info-left,
.project-info-right {
  padding: 5%;
}

.project-info-right {
  width: 90%;
  margin-top: 10%;
}

.project-type {
  color: #FAF9F6;
  font-size: 1.4em;
  text-decoration-line: underline;
  margin-bottom: 0;
  margin-top: 1%;
}

.project-description {
  width: 90%;
  color: #D3D3D3;
  font-weight: bolder;
  font-size: large;
}

.description-title {
  color: #FAF9F6;
  margin: 0;
  font-size: 1.6em;
  text-decoration-line: underline;
}

.technologies-title {
  font-size: 1.4em;
  font-weight: bolder;
  color: #FAF9F6;
  margin-bottom: 0;
  text-decoration-line: underline;
}

.tech-list {
  color: #D3D3D3;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 0;
}

.tech-name {
  font-size: large;
  margin: 3%;
  margin-bottom: 0;
}

.type {
  font-size: large;
  font-weight: bolder;
  color: #D3D3D3;
}

.project-links {
  font-size: 1.4em;
  font-weight: bolder;
  color: #FAF9F6;
  text-decoration-line: underline;
}

.external-links {
  display: flex;
  justify-content: flex-start;
}

.gh-title {
  margin-top: 0;
  margin-right: 2%;
  margin-left: 15%;
  font-weight: bolder;
  font-size: large;
  color: #FAF9F6;
}

.gh-title-only {
  margin-top: 0;
  margin-right: 2%;
  font-weight: bolder;
  color: #FAF9F6;
  font-size: large;
}

.deployed {
  border: 2px solid #F5AFBB;
  cursor: pointer;
  background-color: transparent;
  color: #F5AFBB;
  border-radius: 0.25em;
  height: 30%;
  width: 50%;
  padding: 1%;
  font-size: 18px;
  font-family: 'Thasadith', sans-serif;
  font-weight: bolder;
  line-height: 1;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .project-card {
    grid-template-columns: none;
    height: max-content;
  }

  .project-title {
    margin-right: 15%;
  }

  .project-info-left {
    width: 70%;
    margin-left: 1%;
  }

  .project-imgs {
    width: 80%;
  }

  .project-info-right {
    width: 77%;
    margin-left: 1%;
    padding: none;
  }
}